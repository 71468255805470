// Document Icon:

// Core
import React from 'react'

const Document = () => (
  <svg viewBox="0 0 16 16">
    <defs>
      <clipPath id="iconic-size-sm-document-clip-0">
        <path d="M8 0h-8v16h13v-11h-5v-5zm-6 4h4v1h-4v-1zm0 6h7v1h-7v-1zm9 4h-9v-1h9v1zm0-7v1h-9v-1h9z" />
      </clipPath>
    </defs>
    <g data-width="13" data-height="16" transform="translate(1)">
      <path clipPath="url(#iconic-size-sm-document-clip-0)" d="M0 0h13v16h-13z" />
      <path d="M13 4l-4-4v4z" />
    </g>
  </svg>
)

export default Document
