// Instagram Icon:
// Simple Instagram icon.

// Core
import React from 'react'

const Instagram = () => (
  <svg version="1.1" width="20" height="20" viewBox="0 0 20 20">
    <path d="M2.56844 0C1.15656 0 0 1.15656 0 2.56844V17.4317C0 18.8436 1.15656 20.0002 2.56844 20.0002H17.4317C18.8436 20.0002 20.0002 18.8438 20.0002 17.4317V2.56844C20.0002 1.15656 18.8438 0 17.4317 0H2.56844V0ZM14.5705 2.22172H16.8312C17.3264 2.22172 17.7297 2.62969 17.7297 3.125V5.27828C17.7297 5.77359 17.3264 6.17672 16.8312 6.17672H14.5705C14.0752 6.17672 13.6672 5.77359 13.6672 5.27828V3.125C13.6672 2.62969 14.0752 2.22172 14.5705 2.22172ZM10.0197 6.06453C12.2381 6.06453 14.0383 7.80672 14.0383 9.95609C14.0383 12.1056 12.2381 13.8477 10.0197 13.8477C7.80141 13.8477 6.00109 12.1056 6.00109 9.95609C6.00109 7.80672 7.80141 6.06453 10.0197 6.06453ZM2.22187 8.45719H4.05781C3.89125 9.00109 3.80391 9.57969 3.80391 10.1759C3.80391 13.5027 6.58641 16.1964 10.0197 16.1964C13.453 16.1964 16.2355 13.5027 16.2355 10.1759C16.2355 9.57953 16.1481 9.00109 15.9816 8.45719H17.7394V16.9045C17.7394 17.3417 17.3808 17.7005 16.9434 17.7005H3.01766C2.58047 17.7005 2.22172 17.3419 2.22172 16.9045V8.45719H2.22187Z" />
  </svg>
)

export default Instagram
