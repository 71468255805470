// Hamburger Icon:

import React from 'react'

const Hamburger = () => (
  <svg version="1.1" width="100%" viewBox="0 0 32 23">
    <path stroke="#000" strokeWidth="3" strokeLinecap="square" fill="none" d="M1.5 1.5h29" />
    <path stroke="#000" strokeWidth="3" strokeLinecap="square" fill="none" d="M1.5 11.5h29" />
    <path stroke="#000" strokeWidth="3" strokeLinecap="square" fill="none" d="M1.5 21.5h29" />
  </svg>
)

export default Hamburger
