import * as React from 'react'

const AmedCert = () => (
  <svg width="36px" viewBox="0 0 244 230">
    <title>Advanced Med Code of Ethics Certification</title>
    <path d="M41.93,162.9l6.77-11.2Q60.34,132.38,72,113.06c.62-1,1.16-1.37,2.37-.93,4.83,1.78,9.71,3.45,14.89,5.28L28.76,185.33l-.18-.11c.15-.3.28-.62.46-.92,2.85-4.74,5.69-9.5,8.59-14.22a2,2,0,0,0,0-2.4,102.23,102.23,0,0,1-13.3-38.93,103.63,103.63,0,0,1-.18-25.26,10,10,0,0,0,0-1.13,23.34,23.34,0,0,0-11.29,7.32c-4.62,5.67-5.4,12.16-3.37,18.94,1.07,3.55,2.83,6.88,4.23,10.2a24.85,24.85,0,0,1-6.47-5.31c-9.74-10.86-10.83-30.38,3.58-41.63a45,45,0,0,1,16-7.82,2.79,2.79,0,0,0,2.14-2q20.52-55.86,78.68-69.19a94.86,94.86,0,0,1,24.6-2,2,2,0,0,0,2-1.08c1.88-3.22,3.84-6.4,5.94-9.87a78.69,78.69,0,0,1,3.56,8c1.08,3.33,3.1,4.59,6.56,5.32a104.17,104.17,0,0,1,80.08,79.48c5.87,26.78,1.68,52.17-12.26,75.87A3.12,3.12,0,0,0,218,172q12.88,28.35,25.69,56.74A2.83,2.83,0,0,1,244,230c-.31-.29-.62-.57-.91-.87-11-11.35-21.8-22.86-33-34-19-18.93-38.78-37-60.3-53-15.58-11.61-31.91-22-49.77-29.83a147.55,147.55,0,0,0-39.55-11.45,106.74,106.74,0,0,0-28.29-.49c-1.81.24-2.06,1.13-2.23,2.53a97.55,97.55,0,0,0,5.51,46.81c1.72,4.51,3.86,8.86,5.81,13.28Zm172.34.78c14.38-24.14,18.68-59.65,3-91.21-14.18-28.57-37.22-46.17-68-53.76l-.4.37ZM35.12,82.09c.4,0,.66,0,.91,0,4.32-.34,8.63-.88,12.95-1C61.55,80.8,73.8,83,85.81,86.52c1.39.41,2.1.28,2.89-1q20.2-33.66,40.5-67.25c.28-.47.51-1,.81-1.55C88.66,15.43,48.91,42.3,35.12,82.09Zm74.19,12.76a360,360,0,0,1,54.1,30.09L135.17,65.79Z" />
  </svg>
)

export default AmedCert
